$ff-background-color: #fae7da;

body.foundation-finder-page .page-header,
.ff-quiz {
  background: $ff-background-color;
}

body.foundation-finder-page .page-header.is-alternate,
body.foundation-finder-page .page-header.is-sticky.is-alternate {
  background: #040a2b;
}

body.foundation-finder-page .page-header.is-sticky {
  background: url($base-theme-image-path + 'rgbapng/ffffffa6-10.png');
  background: rgba(255, 255, 255, 0.65);
}

.ff-quiz__inner {
  @include swap_direction(padding, 25px 20px 0 20px);
  max-width: 940px;
  @include swap_direction(margin, 0 auto);
  position: relative;
  &.loading {
    visibility: hidden;
  }
  &.full-width {
    padding: 0;
    max-width: none;
  }
  .ff-quiz__header {
    background: $ff-background-color;
    position: relative;
    z-index: 2;
  }
  .ff-quiz__edit-header {
    display: none;
    text-align: center;
    @include swap_direction(padding, 0 0 20px 0);
    background: $ff-background-color;
    position: relative;
    z-index: 2;
    .ff-quiz--edit & {
      display: block;
    }
  }
  .ff-quiz__edit-headline {
    font-size: 30px;
    font-family: $optimalight;
    letter-spacing: 0.01em;
    line-height: 30px;
    @include swap_direction(margin, 0 0 4px 0);
  }
  .ff-quiz__header-results {
    display: none;
  }
  .ff-quiz__nav-menu {
    display: table;
    min-height: 61px;
    width: 100%;
    background: url('/media/export/cms/hitta-foundation/border-bottom.png') repeat-x bottom;
    font-family: $akzidenz;
    font-size: 12px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @include pie-clearfix;
    @include swap_direction(margin, 0 auto);
  }
  .ff-quiz__nav-item {
    display: table-cell;
    width: 20%;
    text-align: center;
    @include swap_direction(padding, 10px 10px 20px 10px);
    vertical-align: top;
    background: url('/media/export/cms/hitta-foundation/border-right.png') no-repeat right bottom;
    &:last-child {
      background: none;
    }
  }
  .ff-quiz__nav-text {
    @include opacity(0.5);
    @include transition(opacity 0.2s ease-in-out);
  }
  .ff-quiz__nav-label {
    display: block;
  }
  .ff-quiz__nav-value {
    position: relative;
    top: 5px;
    letter-spacing: 0.15em;
    font-weight: bold;
  }
  .ff-quiz__nav-item.active {
    .ff-quiz__nav-text {
      @include opacity(1);
    }
  }
  .ff-quiz__nav-item.selected:hover {
    .ff-quiz__nav-text {
      @include opacity(0.7);
    }
  }
  .ff-quiz__nav-item.selected {
    cursor: pointer;
  }
  .ff-quiz__nav-item--0 {
    display: none;
  }
  .ff-quiz__carousel {
    @include swap_direction(margin, 0 !important);
  }
  .ff-quiz__slide {
    position: relative;
    @include swap_direction(padding, 25px 0 0 0);
    width: 100% !important;
    overflow: hidden;
    display: none;
  }

  // Hide other slides onload until slick carousel in initialized
  // Prevents any possible FOUC
  .slick-initialized .ff-quiz__slide {
    display: block;
  }
  .ff-quiz__slide-image {
    text-align: center;
  }
  .ff-quiz__slide-image--fluid {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  .ff-quiz__headline {
    font-family: $optimalight;
    font-size: 70px;
    line-height: 70px;
    letter-spacing: -0.02em;
    text-align: center;
    @include swap_direction(margin, 0 0 50px 0);
    @include swap_direction(padding, 0 8%);
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
    @media #{$large-up} {
      @include swap_direction(padding, 0 15%);
    }
  }
  .ff-quiz__headline--secondary {
    font-family: $optimalight;
    letter-spacing: -0.02em;
    color: $color-navy;
    font-size: 40px;
    line-height: 42px;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
  }
  .ff-quiz__headline--tertiary {
    font-family: $akzidenz;
    letter-spacing: 0.15em;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    @include swap_direction(margin, 0 0 8px 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ff-quiz__description {
    line-height: 22px;
  }
  .ff-quiz__question {
    @include swap_direction(padding, 0 1%);
    @include pie-clearfix;
    @include swap_direction(margin, 0);
    > li {
      float: $ldirection;
      width: 33.3%;
      text-align: center;
    }
    @media #{$large-up} {
      @include swap_direction(padding, 0 8%);
    }
  }
  .ff-quiz__answer {
    display: block;
    @include opacity(0.5);
    @include transition(opacity 0.2s ease-in);
    outline: none;
    &:hover {
      @include opacity(0.7);
    }
  }
  .ff-quiz__checkbox {
    display: block;
    @include swap_direction(margin, 0 auto 14px auto);
    background: url('/media/export/cms/hitta-foundation/checkbox.png') no-repeat 0 -30px;
    width: 30px;
    height: 30px;
  }
  .ff-quiz__answer.active {
    @include opacity(1);
    .ff-quiz__checkbox {
      background-position: 0 0;
    }
  }
  .ff-quiz__button--next {
    background: $color-navy url('/media/export/cms/hitta-foundation/next-button__arrow-down.png') no-repeat 0 0;
    color: #fff;
    height: 60px;
    width: 100px;
    padding-top: 13px;
    text-align: center;
    text-align: center;
    display: block;
    position: relative;
    @include opacity(0);
    visibility: hidden;
    @include swap_direction(margin, 0 auto);
    font-family: $optimaregular;
    font-size: 16px;
    letter-spacing: 1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include transition(opacity 0.2s ease-in-out);
    &.active {
      @include opacity(1);
      visibility: visible;
    }
    .ff-quiz__button-text--results {
      display: none;
    }
  }
  .ff-quiz__button--results {
    .ff-quiz__button-text--next {
      display: none;
    }
    .ff-quiz__button-text--results {
      display: inline;
    }
  }
  .ff-quiz__edit-close {
    background-size: auto auto;
    background-position: 0 -742px;
    height: 28px;
    width: 28px;
    position: absolute;
    top: 20px;
    #{$rdirection}: 0;
    cursor: pointer;
    display: none;
    .ff-quiz--edit & {
      display: block;
    }
  }
  .ff-quiz__filter-button-wrapper {
    position: absolute;
    #{$ldirection}: 0;
    bottom: 20px;
    text-align: center;
    width: 100%;
    display: none;
    .ff-quiz--edit & {
      display: block;
    }
  }
  .ff-quiz__footer {
    @include swap_direction(padding, 0);
    position: fixed;
    width: 100%;
    #{$ldirection}: 0;
    bottom: $sticky-footer-height;
  }
  .ff-quiz__footer-container {
    @include swap_direction(padding, 0 20px);
    @include swap_direction(margin, 0 auto);
    position: relative;
  }
  .ff-quiz__link--help {
    position: absolute;
    bottom: 30px;
  }
  .ff-quiz__start-over-link,
  .ff-quiz__link--help {
    letter-spacing: 0.1em;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid $color-navy;
    padding-bottom: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ff-quiz__slider {
    @include swap_direction(margin, 0 auto 30px auto);
    width: 83%;
    position: relative;
    z-index: 100;
  }

  ///
  /// Slide Variations
  ///

  .ff-quiz__slide--1 {
    .ff-quiz__headline {
      margin-bottom: 20px;
    }
    .ff-quiz__question {
      //width: 690px;
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0 auto);
      position: relative;
      text-align: center;
      top: -45px;
      > li {
        @include swap_direction(padding, 40px 15px 0 15px);
        background: url('/media/export/cms/hitta-foundation/sliderui-marker.png') no-repeat 50% 0;
      }
    }
  }
  .ff-quiz__slide--4 {
    .ff-quiz__headline {
      margin-bottom: 0;
      position: relative;
      z-index: 1;
    }
    .ff-quiz__slider {
      width: 95%;
      margin-top: -141px;
    }
    .ff-quiz__question {
      width: 83%;
      //width: 690px;
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0 auto);
      position: relative;
      text-align: center;
      top: -45px;
      > li {
        width: 12.5%; //14.28571%
        @include swap_direction(padding, 40px 5px 0 5px);
        background: url('/media/export/cms/hitta-foundation/sliderui-marker.png') no-repeat 50% 0;
      }
    }
  }
  .ff-quiz__slide--5 {
    .ff-quiz__question {
      .ff-quiz__checkbox {
        margin-bottom: 20px;
      }
    }
  }
  .ff-quiz__coverage-stage,
  .ff-quiz__intensity-stage {
    position: relative;
    max-width: 580px;
    height: 200px;
    @include swap_direction(margin, 0 auto 5px auto);
    text-align: center;
  }
  .ff-quiz__intensity-stage {
    max-width: 750px;
    height: 366px;
    @include swap_direction(margin, 0 auto);
    top: -30px;
    z-index: 0;
  }
  .ff-quiz__coverage-image,
  .ff-quiz__intensity-image {
    position: absolute;
    width: 100%;
    background: $ff-background-color;
    color: $color-navy;
    font-family: $optimalight;
    font-size: 180px;
    line-height: 170px;
    letter-spacing: -0.05em;
    @include swap_direction(margin, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.ff-quiz__coverage-image--slide,
    &.ff-quiz__intensity-image--slide {
      @include opacity(0.2);
    }
    &.ff-quiz__intensity-image--slide {
      top: 55px;
    }
    &.ff-quiz__coverage-image--sheer,
    &.ff-quiz__coverage-image--medium,
    &.ff-quiz__coverage-image--full,
    &.ff-quiz__intensity-image--01,
    &.ff-quiz__intensity-image--02,
    &.ff-quiz__intensity-image--03,
    &.ff-quiz__intensity-image--04,
    &.ff-quiz__intensity-image--05,
    &.ff-quiz__intensity-image--06,
    &.ff-quiz__intensity-image--07,
    &.ff-quiz__intensity-image--08 {
      display: none;
    }
  }
  .ff-quiz__coverage-image--thumb,
  .ff-quiz__intensity-image--thumb {
    position: absolute;
    top: -10px;
    #{$ldirection}: 0;
    display: block;
    z-index: 1;
    max-width: 100%;
    width: 100%;
    height: auto;
    @media #{$medium-up} {
      #{$ldirection}: 76px;
    }
  }
  .ff-quiz__intensity-image--thumb {
    top: 0;
    #{$ldirection}: 0;
  }
} //.ff-quiz

// Edit
.ff-quiz--edit {
  margin-bottom: 50px;
  @include box-shadow(rgba(black, 0.4) 0 -10px 10px -12px inset);
  .ff-quiz__inner {
    padding-top: 10px;
    .ff-quiz__footer,
    .ff-quiz__slide-image,
    .ff-quiz__coverage-stage,
    .ff-quiz__intensity-stage {
      display: none;
    }
    .ff-quiz__nav-menu {
      background: none;
      margin-bottom: 5px;
    }
    .ff-quiz__nav-value {
      padding-#{$rdirection}: 13px;
      #{$ldirection}: 7px;
      background: url('/media/export/cms/hitta-foundation/arrow-small_down.png') no-repeat right 4px;
    }
    .ff-quiz__nav-item.active {
      .ff-quiz__nav-value {
        background: url('/media/export/cms/hitta-foundation/arrow-small_up.png') no-repeat right 3px;
      }
    }
    .ff-quiz__carousel-wrapper {
      position: relative;
      @include transition(height 0.4s ease);
      overflow: hidden;
      height: 270px;
    }
    .ff-quiz__carousel {
      border-top: 1px solid #b0a4a5;
    }
    .ff-quiz__slide {
      height: 270px !important;
      padding-top: 20px;
    }
    .ff-quiz__headline {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 25px;
    }
    .ff-quiz__checkbox {
      width: 20px;
      height: 20px;
      @include swap_direction(margin, 0 auto 7px auto !important);
      //float: $ldirection;
      background-position: 0 -20px;
      background-size: 100%;
      #{$ie8} & {
        width: 30px;
        height: 30px;
        background-position: 0 -30px;
      }
    }
    .ff-quiz__answer.active {
      .ff-quiz__checkbox {
        #{$ie8} & {
          background-position: 0 0;
        }
      }
    }
    .ff-quiz__headline--secondary {
      //text-align: $ldirection;
      font-family: $akzidenz;
      letter-spacing: 1px;
      font-size: 15px;
      line-height: 20px;
      //float: $ldirection;
    }
    .ff-quiz__headline--tertiary {
      margin-bottom: 5px;
    }
    .ff-quiz__description {
      @include swap_direction(margin, 0);
    }
    .ff-quiz__slide--1 .ff-quiz__headline,
    .ff-quiz__slide--4 .ff-quiz__headline {
      margin-bottom: 35px;
    }
    .ff-quiz__slide--4 .ff-quiz__slider {
      margin-top: 0;
    }
  }
  &.collapsed .ff-quiz__inner .ff-quiz__carousel-wrapper {
    height: 0;
  }
}
