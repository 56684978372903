.ecommerce {
  .sign-in-component {
    &__birthday-program {
      float: none !important;
      margin: 0 auto;
    }
  }
}

.page-footer {
  @media #{$medium-only} {
    .responsive-footer {
      .menu {
        li.last {
          a.more {
            line-height: 40px;
            height: 40px;
            margin: 0;
            padding-left: 30px;
          }
        }
      }
    }
  }
  .footer-need-help {
    .menu-container {
      .menu {
        .last {
          .level-2 {
            pointer-events: none;
          }
        }
      }
    }
  }
}

.store-locator {
  .local-search__search-container {
    .local-search-form {
      .local-search-form__city-state-wrapper {
        .local-search-form__city {
          width: 100% !important;
        }
      }
    }
  }
}

#address_form_container {
  .form-item {
    &.address2_container {
      .required_mark {
        display: none;
      }
    }
  }
}

.spp {
  .product-full {
    &__description-section {
      .product__inventory-status {
        .temp-out-of-stock,
        .coming-soon {
          span {
            display: block;
            margin-bottom: 5px;
          }
          a.button {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.product-full {
  &__description-section {
    .social-share-icons {
      display: none;
    }
  }
}

#order_detail {
  .order-details-page {
    &__content {
      .order-details__item {
        margin-bottom: 50px;
      }
    }
  }
}

.contact-us-page {
  .contact-form {
    &__section {
      .contact-form__sub-question-type {
        label[for='question-type-2-d'] {
          display: none;
        }
      }
    }
  }
}

.email_popover {
  .email_popover {
    &__social_icons {
      .twitter,
      .pinterest,
      .youtube {
        display: none;
      }
    }
  }
}

.spp-product__anchor {
  .spp-product__anchor-nav {
    padding-top: 15px;
    .spp-product__anchor-help {
      display: none;
    }
    li:nth-child(1) {
      margin-top: 5px;
      margin-bottom: 16px;
    }
  }
  .spp-product__anchor-tab {
    top: 0;
  }
}

.quickshop {
  .quickshop__reviews {
    display: none;
  }
}

.ff-quiz {
  .ff-quiz__inner {
    .ff-quiz {
      &__slide--4 {
        .ff-quiz__question > li {
          width: 14.28%;
        }
      }
    }
  }
}

.ff-results {
  .ff-results {
    &__regimen-steps,
    &__matches-sub-headline {
      display: none;
    }
  }
}

.spp-howtouse-formatter {
  .spp-howtouse {
    .spp_howtouse__header {
      .headline--secondary {
        font-size: 50px;
      }
    }
  }
}

.home-formatter {
  .hero-block__headline {
    .header__headline {
      @media #{$large-down} {
        font-size: 6.8vw;
        line-height: 0.9;
      }
      @media #{$xlarge-up} {
        font-size: 7.8vw;
        line-height: 0.9;
      }
    }
  }
  .estee_candy {
    .hero-block__promo {
      bottom: 0;
    }
  }
}

.ee-tout-wrapper {
  .ee-tout-inner {
    .ee-tout__logo {
      margin-top: 0;
    }
  }
}

.section-boutique {
  .page-wrapper {
    .boutique-lip-potion-page {
      .lip-potion__frame {
        #lip-potion__frame5Row1 {
          display: none;
        }
      }
    }
  }
}

.device-pc {
  #colorbox {
    &.welcome15-overlay {
      #cboxLoadedContent {
        .email_popover {
          .email_popover__social_icons {
            top: auto;
            bottom: -30px;
          }
          .welcome_subhead {
            font-size: 45px;
            font-family: OptimaDisplayLight;
            letter-spacing: -0.02px;
            margin-bottom: 15px;
            margin-top: 10px;
            line-height: 60px;
          }
          &__shop_now_link {
            color: $color-white;
            font-weight: normal;
            text-decoration: none;
            a {
              text-decoration: underline;
              font-weight: normal;
            }
          }
          .thank_you_subhead {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.15em;
            text-transform: uppercase;
          }
          .welcome__promo-header {
            span {
              font-size: 40px;
              line-height: 42px;
              margin-top: 15px;
              font-family: $optimaregular;
            }
          }
          .loyalty_popover__status {
            a {
              color: #9eafff;
              text-decoration: underline;
            }
          }
          .button.cta {
            color: #9eafff;
            border-color: #9eafff;
          }
          .welcome_validity {
            font-size: 12px;
          }
        }
      }
    }
  }
}

#confirm,
#samples {
  .page-sticky-footer {
    &.drawer-container {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
}

.renutriv_section_wrapper {
  .renutriv_section {
    .propoContainerReady {
      ul li.li1 {
        left: 20% !important;
      }
    }
  }
}

.add_cart_response {
  #cboxLoadedContent {
    margin: 35px 0 0 0;
    height: 170px;
  }
}

.customer-service {
  .sidebar-page__content {
    .cs-quick-info {
      li:nth-child(1) {
        .cs-quick-info__text,
        .cs-quick-info__title {
          pointer-events: none;
          a {
            pointer-events: none;
          }
        }
      }
      li.cs-quick-info__block--call-us {
        .cs-quick-info__text {
          background-size: auto auto;
          background-position: 0px -1939px;
          padding-left: 15px;
        }
      }
    }
    li {
      min-height: auto;
    }
  }
}

.homepage_module_wrapper {
  .headline_content_group {
    .headline_content__headline {
      line-height: 95px;
    }
    .headline_content__subcopy {
      line-height: 21.6px;
    }
  }
}

.hero-block__container {
  .hero-block__headline {
    .header__headline.headline--large.dark {
      font-size: 70px;
      span {
        div {
          font-size: 70px !important;
        }
      }
    }
  }
}

.home-block {
  .multi-use-style-b-revitalizing-supreme-elc-nodeblock {
    .hero-block__hero {
      max-width: 1366px;
      margin: 0 auto;
    }
  }
}

.spp-tout-anr-eye-comparison-tout-elc-nodeblock {
  .hero-block__container {
    .hero-block__promo {
      top: 72% !important;
      .hero-block__promo-copy {
        .text-promo__subhead {
          span:first-child {
            width: 292px;
          }
          span:last-child {
            width: 260px;
          }
        }
        .text-promo__subcopy {
          line-height: 1.4em;
          div:first-child {
            float: left;
            width: 292px;
            display: inline;
          }
          div {
            float: left;
            width: 260px;
            display: inline;
          }
        }
      }
    }
  }
}

.spp-itemset-anr-eye-vba-video-how-to-use-spp-related-content-item-set {
  .discover-more {
    padding-top: 55px;
  }
}

.mpp-skincare-repair-serum-page {
  .multi_use_mpp_v1 {
    .headline_content__headline {
      line-height: 80px;
    }
  }
}

.page-product-product-catalog-skincare-hydrationist-maximum-moisture-creme {
  .spp-itemset-skincare-vba-spp-related-content-item-set {
    i {
      font-style: normal;
    }
  }
  .back-to-top-show {
    span {
      font-style: normal;
    }
  }
}

.mpp-skincare-moisturizer-page {
  .multi_use_mpp_v1 {
    .headline_content__headline {
      p {
        line-height: 75px;
        font-size: 75px;
      }
    }
  }
}

body.has-mobile-checkout-nav {
  .responsive-footer {
    display: block !important;
  }
}

.section-promotions {
  h3.formatter-123__title-2 {
    margin-bottom: 40px;
    padding-top: 5%;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-light-gray;
  }
}

.brand-aerin {
  .mpp.mpp--brand-aerin {
    ul.mpp__product-grid {
      li.mpp__product {
        a.product_brief__button--benefits {
          display: none;
        }
        a.product_brief__button--shop-now {
          margin-left: 26px;
        }
      }
    }
  }
}

.mpp-skincare-targeted-treatments-page {
  .hero-block-wrapper {
    .hero-block__headline {
      .header__headline {
        font-size: 105px;
      }
    }
  }
}

.welcome15-overlay.alt_layout1 {
  .email_popover {
    .email_popover__content {
      p:nth-last-child(2) {
        text-transform: lowercase;
      }
    }
  }
}
