// Abstract specific font names away
$conv_optima: 'Conv_OptimaEL-Text';
$conv_optima_noval: 'Conv_OptimaNovaLTPro-Light';

@font-face {
  font-family: '#{$conv_optima}';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: '#{$conv_optima_noval}';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaNovaLTPro-Light.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

// setting it here so it doesn't affect font inclusions
$optimalight: $optimaregular;
