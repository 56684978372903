///
/// @file _settings.scss
///
/// \brief Pull all parts of the theme scss together into one file
/// \author John A Tilley
///
/// For locale overrides we should use a settings file to define our overrides
/// for fonts and spacing etc. This will make our frontend framework on par
/// with frameworks such as Bootstrap and Foundation. It allows us to define
/// our variables as !default and cleanly override them in a single and
/// organised settings file. This prevents overrides being made further down
/// the style sheets with recalls to base files.
///
/// This file should only ever contain variables and have no outputs. Ideally
/// it should not have any dependancies and will be at the very top of any
/// locale file.
///

// Table of contents:
// ------------------
//
// 1. Typography
// Please extend this TOC

// 1. Typography
// -------------

$optimalight: 'Optima Display Roman';

// Note: it would be better if these variables were cast into more descriptive
// names. eg. $header-font-family, $body-font-family, etc
