/* Mixin function for review star Background image */
@mixin pr-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 10px;
  $pr-width-value: 12px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/global/stjaernor/rating-small-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for review star Large Background image */
@mixin pr-large-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 40px;
  $pr-width-value: 45px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/global/stjaernor/rating-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for Aerin review star Background image */
@mixin pr-aerin-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 10px;
  $pr-width-value: 12px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/global/stjaernor/rating-lblue-small-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for Aerin review star Large Background image */
@mixin pr-aerin-large-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 40px;
  $pr-width-value: 45px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/global/stjaernor/rating-lblue-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for Renutriv review star Background image */
@mixin pr-renutriv-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 10px;
  $pr-width-value: 12px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/global/stjaernor/rating-gold-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for Renutriv review star Large Background image */
@mixin pr-renutriv-large-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 40px;
  $pr-width-value: 45px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/global/stjaernor/rating-gold-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* SPP page */
.review-snippet,
.search-product__details {
  .p-w-r {
    @include pr-background-image();
    margin: 0;
    #{$aerin} & {
      @include pr-aerin-background-image();
    }
    #{$renutriv} & {
      @include pr-renutriv-background-image();
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal,
      &.pr-snippet-compact,
      &.pr-snippet-standard {
        .pr-snippet-read-and-write {
          letter-spacing: 1.8px;
          text-transform: uppercase;
          font-family: $akzidenz;
          #{$renutriv} & {
            font-family: $roboto-condensed;
          }
          a {
            font-size: 12px !important;
            color: $color-navy;
            #{$aerin} & {
              font-family: $futura_now_md;
              color: $color-periwinkle-blue--aerin;
            }
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
            box-shadow: none;
            outline: none;
            padding: 0 0 0 8px;
            margin: 0;
          }
          .pr-snippet-write-review-link {
            display: none;
          }
          .pr-snippet-review-count {
            &:before {
              content: '(';
              visibility: visible;
            }
            &:after {
              content: ')';
              visibility: visible;
            }
          }
        }
        .pr-snippet-stars-container {
          vertical-align: sub;
          display: inline-block;
          .pr-snippet-rating-decimal {
            display: none;
          }
          .pr-snippet-stars-png {
            background: transparent;
            .pr-star-v4 {
              width: 12px;
              height: 10px;
            }
          }
        }
        .pr-snippet-reco-to-friend {
          display: none;
        }
      }
    }
    .pr-no-reviews {
      .pr-snippet {
        @media #{$small-only} {
          text-align: center;
        }
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            display: none;
          }
          .pr-snippet-write-review-link {
            display: inline;
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }
    .pr-snippet {
      padding: 0 0 8px 0;
    }
  }
}
/* MPP page */
.mpp__product {
  .review-snippet {
    .p-w-r {
      .pr-no-reviews {
        display: none;
      }
      .pr-snippet {
        font-size: 12px;
        line-height: 16px;
        height: 16px;
        margin-top: 5px;
        font-family: $akzidenz;
        color: $color-navy;
        padding: 0;
        #{$aerin} & {
          color: $color-periwinkle-blue--aerin;
          padding: 0 0 8px;
        }
        #{$renutriv} & {
          color: $color-white;
        }
        .pr-snippet-stars-png {
          #{$renutriv} & {
            background-color: transparent;
          }
          .pr-snippet-rating-decimal {
            display: none;
          }
        }
        .pr-category-snippet__total {
          display: block;
          margin-top: 5px;
          &:before {
            content: '(';
            visibility: visible;
          }
          &:after {
            content: ')';
            visibility: visible;
          }
        }
        .pr-star-v4 {
          width: 12px;
          height: 10px;
        }
      }
      .pr-no-reviews {
        .pr-category-snippet__total {
          display: none;
        }
      }
      .pr-snippet-stars {
        background: transparent;
      }
    }
  }
}

.mpp,
.mobile-search-page {
  .product-brief {
    .p-w-r {
      .pr-category-snippet__item {
        @media #{$small-only} {
          display: inherit;
        }
      }
      .pr-snippet {
        .pr-star-v4 {
          width: 12px;
          height: 10px;
        }
        font-family: $akzidenz;
        font-size: 12px;
      }
      .pr-category-snippet__total {
        display: block;
        margin-top: 5px;
        #{$aerin} & {
          color: $color-periwinkle-blue--aerin;
        }
        &:before {
          content: '(';
          visibility: visible;
        }
        &:after {
          content: ')';
          visibility: visible;
        }
      }
      .pr-snippet-stars {
        #{$renutriv} & {
          background: $color-black;
        }
      }
      .pr-no-reviews {
        display: none;
      }
    }
    .pr-category-snippet {
      display: block;
      text-align: center;
    }
    .product_brief__stars,
    .product-brief__reviews-count {
      display: none;
    }
  }
  .product_brief__reviews {
    display: none;
  }
}

.spp__reviews_head {
  background-color: $color-gray-cs-callout;
  @media #{$small-only} {
    .spp-product__reviews-header--mobile {
      text-align: center;
    }
  }
  #{$renutriv} & {
    background-color: $color-medium-black;
  }
  .p-w-r {
    .pr-review-snapshot,
    .pr-review-display {
      background-color: $color-gray-cs-callout;
      #{$renutriv} & {
        background-color: $color-medium-black;
      }
      .pr-rd-footer {
        background-color: $color-gray-cs-callout;
        #{$renutriv} & {
          background-color: $color-medium-black;
        }
      }
    }
    .pr-snippet-stars {
      background-color: transparent;
    }
  }
  .spp-product__reviews {
    width: 800px;
    margin: 0 auto;
    height: auto;
    margin-bottom: 5%;
    @media #{$small-only} {
      width: auto;
      margin: 0 25px;
    }
    @media #{$medium-only} {
      width: auto;
      padding: 15px;
    }
  }
}

.spp-reviews {
  width: 800px;
  @media #{$medium-only} {
    width: auto;
    padding: 15px;
  }
  margin: 0 auto;
  padding: 50px 0;
  &.spp-product__reviews {
    #{$renutriv} & {
      background-color: $color-medium-black;
    }
  }
  .pr-header {
    color: $color-navy;
    #{$aerin} & {
      color: $color-periwinkle-blue--aerin;
      font-family: $futura_now_reg;
    }
    #{$renutriv} & {
      color: $color-white;
      border-bottom: 1px solid $color-gold--renutriv;
    }
    font-size: 40px;
    font-weight: normal;
    line-height: normal;
    font-family: $optimalight;
    text-align: center;
    border-bottom: 1px solid $color-light-gray;
    padding-bottom: 15px;
  }
}
/* To display a Review description posted by user */
#pr-reviewdisplay {
  .p-w-r {
    margin: 0;
    a,
    span,
    select {
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .pr-review-snapshot-faceoff,
    .pr-review-snapshot-msq-container {
      display: none;
    }
    .pr-review-snapshot {
      @include pr-large-background-image;
      #{$aerin} & {
        @include pr-aerin-large-background-image();
      }
      #{$renutriv} & {
        @include pr-renutriv-large-background-image();
      }
      .pr-review-snapshot-snippets {
        .pr-snippet-reco-to-friend {
          display: block;
          max-width: initial;
          padding: 0 24px 0px 24px;
          @media #{$small-only} {
            padding: 0px 58px 0px 65px;
          }
          .pr-reco-to-friend-message {
            max-width: initial;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
          }
          .pr-checkbox-icon {
            display: none !important;
          }
          .pr-reco-value {
            font-size: 12px;
          }
          .pr-reco {
            margin-right: 0;
            span {
              line-height: 17px;
              margin-right: 0px;
              font-weight: bold;
            }
          }
          div,
          span {
            display: inline !important;
          }
        }
        .pr-snippet-stars-reco-stars {
          .pr-snippet-stars-container {
            display: inline-block;
          }
          .pr-snippet-read-and-write {
            .pr-snippet-review-count {
              display: none;
            }
            .pr-snippet-write-review-link {
              font-size: 12px !important;
              font-weight: bold;
              text-transform: uppercase;
              background: $color-navy;
              #{$aerin} & {
                background: $color-periwinkle-blue--aerin;
                font-family: $futura_now_md;
                letter-spacing: 0.15em;
                border: 1px solid $color-periwinkle-blue--aerin;
              }
              #{$renutriv} & {
                background: $color-medium-black;
                border: 1px solid $color-gold--renutriv;
                font-family: $roboto-condensed;
                letter-spacing: 0.15em;
              }
              border: 1px solid $color-black;
              width: auto;
              padding: 8px 16px;
              overflow: visible;
              vertical-align: middle;
              color: $color-white;
              position: relative;
              top: 46px;
              &:hover,
              &:active {
                text-decoration: none;
              }
              @media #{$small-only} {
                display: block;
              }
            }
            margin-top: 7px;
            display: block;
          }
          float: none;
          margin-top: 20px;
          height: 85px;
          @media #{$small-only} {
            margin-top: 0;
          }
          .pr-star-v4 {
            height: 30px;
            width: 35px;
            margin-right: 5px;
          }
          .pr-snippet-stars-png {
            display: block;
            #{$renutriv} & {
              background-color: transparent;
            }
          }
          .pr-snippet-rating-decimal {
            display: block;
            border: none;
            background: transparent;
            font-size: 40px;
            color: $color-navy;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
              font-family: $futura_now_reg;
            }
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
            float: none;
            margin-left: 0;
            font-family: $optimalight;
            padding: 10px 0;
            &:after {
              content: ' out of 5';
              color: $color-light-gray;
              #{$aerin} & {
                font-family: $futura_now_reg;
              }
            }
          }
        }
        @media #{$small-only} {
          min-height: 140px;
        }
      }
      .pr-review-snapshot-header-intro {
        display: none;
      }
      text-align: center;
      margin: 0 auto;
      @media #{$small-only} {
        width: auto;
      }
      .pr-snippet-stars-reco-inline {
        .pr-snippet-stars-reco-reco {
          float: none;
          margin-top: 9px;
        }
      }
      .pr-review-snapshot-block-pros {
        border: none;
        .pr-snapshot-tag-def {
          .pr-filter-btn {
            text-align: center;
            background-color: transparent;
            #{$renutriv} & {
              background-color: $color-medium-black;
            }
            .pr-filter-btn__voteCount {
              display: none;
            }
            .pr-filter-btn__buttonText {
              font-family: $optimalight;
              font-size: 25px;
              text-transform: lowercase;
            }
            &:focus {
              outline: none;
              box-shadow: none;
            }
            .pr-cross-icon {
              right: -12px;
            }
          }
          &:last-child {
            .pr-filter-btn__buttonText {
              &::after {
                content: ' ';
              }
            }
          }
        }
        .pr-review-snapshot-block-headline {
          font-size: 12px;
          font-weight: bold;
          text-align: center;
          font-family: $akzidenz;
          padding: 12px 30px 0;
          line-height: 1.2;
          letter-spacing: 1.2px;
          text-transform: uppercase;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin !important;
            font-family: $futura_now_md;
          }
          #{$renutriv} & {
            background-color: $color-medium-black;
            color: $color-white;
            font-family: $roboto-condensed;
          }
          @media #{$small-only} {
            padding: 0;
          }
        }
        .pr-snapshot-tag-def-filtering {
          padding: 0;
          height: auto;
          line-height: 1;
          margin-top: 0;
          &:hover,
          &:active {
            #{$renutriv} & {
              outline: none;
              box-shadow: none;
              background-color: $color-black;
            }
          }
        }
        .pr-review-snapshot-tags {
          dt {
            padding-bottom: 5px;
            #{$renutriv} & {
              background-color: $color-medium-black;
              color: $color-white;
            }
          }
        }
      }
      .pr-review-snapshot-block-histogram {
        border: none;
        @media #{$small-only} {
          @media #{$portrait} {
            margin-top: 23%;
          }
          @media #{$landscape} {
            margin-top: 16%;
          }
        }
        .pr-review-snapshot-block-headline {
          display: none;
        }
        .pr-ratings-histogram-barValue {
          background: $color-navy;
          #{$aerin} & {
            background: $color-periwinkle-blue--aerin;
          }
          #{$renutriv} & {
            background: $color-medium-black;
          }
        }
        .pr-ratings-histogram-bar {
          background: $color-lighter-gray;
        }
        .pr-histogram-label {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 12px;
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
          }
          #{$renutriv} & {
            color: $color-white;
          }
        }
        .pr-histogram-count {
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
          }
          #{$renutriv} & {
            color: $color-white;
          }
        }
      }
      .pr-review-snapshot-block-cons {
        border: none;
        .pr-review-snapshot-tags {
          display: none;
        }
      }
      .pr-rating-stars {
        float: none;
      }
      .pr-review-snapshot-block {
        min-height: 195px;
        @media #{$small-only} {
          min-height: 0;
        }
      }
      .pr-ratings-histogram {
        @media #{$small-only} {
          margin: 0;
        }
        .pr-histogram-stars {
          height: 36.4px;
          &:active {
            .pr-histogram-label,
            .pr-histogram-count {
              color: $color-light-blue;
            }
          }
          #{$renutriv} & {
            &:hover {
              background-color: transparent;
            }
          }
          &:focus {
            box-shadow: none;
          }
        }
        .pr-hist-filter {
          &-5,
          &-4,
          &-3,
          &-2,
          &-1 {
            #{$renutriv} & {
              background-color: transparent;
            }
            .pr-ratings-histogram-barValue {
              background-color: $color-light-blue;
              border: none;
            }
            &:hover {
              background-color: $color-light-blue;
            }
          }
        }
      }
      .pr-review-snapshot-content-block {
        margin-bottom: 0;
      }
      .pr-review-snapshot-block-container {
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-light-gray;
        #{$renutriv} & {
          border-bottom: 1px solid $color-gold--renutriv;
          background-color: $color-medium-black;
        }
        @media #{$small-only} {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        margin-bottom: 0;
        .pr-clearfix {
          height: 30px;
          clear: $rdirection;
          @media #{$medium-up} {
            height: 80px;
          }
        }
        .pr-rd-review-header-contents + .pr-clearfix {
          height: 20px;
          @media #{$medium-up} {
            height: 40px;
          }
        }
        .pr-rd-review-total {
          display: block;
          font-family: $akzidenz;
          color: $color-navy;
          font-size: 15px;
          padding: 0;
          text-align: center;
          letter-spacing: 0.05em;
          font-weight: 700;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
            font-family: $futura_now_md;
          }
          #{$renutriv} & {
            color: $color-white;
            font-family: $roboto-condensed;
          }
        }
        .pr-rd-sort {
          font-size: 15px;
          letter-spacing: 1.8px;
          font-family: $akzidenz;
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
            font-family: $futura_now_md;
          }
          #{$renutriv} & {
            color: $color-white;
            font-family: $roboto-condensed;
          }
          text-transform: uppercase;
          border: solid 1px $color-navy;
          @include swap_direction(padding, 0 10px 2px 0);
          float: right;
          &:focus {
            outline: none;
            box-shadow: none;
          }
          @media #{$small-only} {
            padding-left: 0;
          }
          -moz-appearance: none;
        }
        .pr-rd-review-header-sorts {
          @include swap_direction(padding, 15px 0 0 28px);
          width: auto;
          float: right;
          @media #{$small-only} {
            width: 100%;
            display: inline-block;
            @include swap_direction(padding, 0 0 20px 28px);
          }
          @media #{$medium-only} {
            text-align: right;
            padding: 0;
          }
          .pr-rd-sort-group {
            position: relative;
            left: 15px;
            text-align: right;
            &:before {
              content: $reviews_sort;
              text-transform: uppercase;
              position: relative;
              top: 7px;
              font-family: $akzidenz;
              font-size: 13px;
              color: $color-navy;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
                font-family: $futura_now_md;
              }
              #{$renutriv} & {
                color: $color-white;
                font-family: $roboto-condensed;
                top: 10px;
              }
              padding-right: 5px;
              letter-spacing: 1.8px;
              @media #{$small-only} {
                top: 13px;
              }
            }
            @media #{$small-only} {
              padding-top: 0;
            }
          }
        }
        .pr-rd-review-header-contents {
          padding: 30px 0 0;
          width: 102%;
          max-height: 100px;
          text-align: left;
          clear: both;
          margin-top: 2%;
          flex-flow: row wrap;
          justify-content: flex-start;
          @media #{$medium-up} {
            padding: 40px 0 0;
            width: 69%;
            max-height: 40px;
          }
        }
        .pr-multiselect {
          white-space: nowrap;
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            background-color: $color-navy;
            #{$aerin} & {
              background: $color-periwinkle-blue--aerin;
            }
            #{$renutriv} & {
              background-color: $color-medium-black;
              border: 1px solid $color-light-gray;
            }
            width: 40px;
            .pr-search-icon {
              display: initial;
              &::after {
                content: 'GO';
                color: $color-white;
                font-weight: bold;
                font-size: 12px;
                font-family: $akzidenz;
              }
              svg {
                display: none;
              }
            }
            @media #{$small-only} {
              width: 42px;
              height: 42px;
            }
          }
          .pr-rd-search-reviews-input {
            padding-right: 50px;
            @media #{$small-only} {
              padding-bottom: 10px;
            }
            input {
              background: none;
              &:active,
              &:focus {
                + button {
                  svg {
                    path {
                      fill: $color-white;
                    }
                  }
                }
              }
              @media #{$small-only} {
                padding: 20px 12px;
                border-radius: 0;
              }
              #{$renutriv} & {
                color: $color-white;
                font-family: $roboto-condensed;
              }
              padding-right: 10px;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
                font-family: $futura_now_md;
              }
            }
            .pr-rd-search-reviews-clear-button {
              right: 48px;
              &:active,
              &:focus {
                outline: none;
                box-shadow: none;
                border: none;
              }
              svg {
                &:active,
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
                .pr-cross-icon__line {
                  stroke: $color-navy;
                }
                g {
                  &:active,
                  &:focus {
                    outline: none;
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }
        .pr-rd-review-header-contents {
          .pr-multiselect-button {
            color: $color-navy;
            padding: 6px 0;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
            }
            #{$renutriv} & {
              color: $color-white;
              background-color: $color-medium-black;
            }
            background: $color-gray-cs-callout;
            border: none;
            min-width: initial;
            display: inline;
            vertical-align: middle;
            .pr-caret-icon {
              background-image: url('/media/images/background-images/background-image13.png');
              background-size: 23px 43px;
              -webkit-appearance: none;
              background-repeat: no-repeat;
              background-position: 6px;
              transform: rotate(0deg);
              display: inline-block;
              vertical-align: middle;
              #{$aerin} & {
                background-image: url('/media/images/background-images/background-image15.png');
                background-size: 23px 43px;
              }
              #{$renutriv} & {
                background-image: url('/media/images/background-images/background-image14.png');
                background-size: 23px 43px;
              }
              -webkit-appearance: none;
              background-repeat: no-repeat;
              background-position: 6px;
              transform: rotate(0deg);
              svg {
                display: none;
              }
              @media #{$small-only} {
                @media #{$portrait} {
                  right: 0;
                }
                margin-top: 0;
              }
            }
            &:focus {
              box-shadow: none;
              outline: none;
            }
            .pr-multiselect-count {
              background: $color-lighter-gray;
              #{$renutriv} & {
                color: $color-medium-black;
              }
              @media #{$small-only} {
                @media #{$portrait} {
                  display: none;
                }
              }
            }
            span {
              font-size: 13px;
              text-transform: uppercase;
              letter-spacing: 0.15em;
              @media #{$small-only} {
                @media #{$portrait} {
                  letter-spacing: 0.5px;
                }
              }
              @media #{$iphone5-portrait} {
                font-size: 10px;
              }
            }
            @media #{$small-only} {
              @media #{$portrait} {
                padding-right: 10px;
                margin-right: 0px;
              }
            }
            @media #{$iphone6plus-portrait} {
              padding-right: 10px;
              margin-right: 10px;
            }
          }
          .pr-multiselect-options {
            width: auto;
            max-height: initial;
            overflow: auto;
            border: 1px solid $color-black;
            #{$renutriv} & {
              background: $color-medium-black;
              border: 1px solid $color-gold--renutriv;
            }
            label {
              display: inline-block;
              span {
                font-size: 12px;
              }
            }
          }
          .pr-multiselect-button-myageis {
            display: flex;
            .pr-multiselect-options {
              ul {
                width: 140px;
              }
            }
            &:before {
              content: 'FILTER :';
              font-size: 13px;
              margin-right: 20px;
              letter-spacing: 0.15em;
              color: $color-navy;
              margin-top: 7px;
              white-space: nowrap;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
              }
              #{$renutriv} & {
                color: $color-white;
                font-family: $roboto-condensed;
              }
              vertical-align: middle;
              @media #{$small-only} {
                @media #{$portrait} {
                  margin-right: 3px;
                  letter-spacing: 0;
                  font-size: 12px;
                }
              }
              @media #{$iphone5-portrait} {
                font-size: 10px;
              }
            }
          }
          .pr-multiselect-button-myskintypeis {
            .pr-multiselect-options {
              @media #{$small-only} {
                @media #{$portrait} {
                  left: -50%;
                }
              }
              ul {
                width: 253px;
                @media #{$small-only} {
                  @media #{$portrait} {
                    width: 198px;
                  }
                }
              }
            }
          }
          .pr-multiselect-button-myskinconcernis {
            .pr-multiselect-options {
              @media #{$small-only} {
                @media #{$portrait} {
                  left: -44%;
                }
              }
              @media #{$iphone5-portrait} {
                left: -65%;
              }
              ul {
                width: 200px;
                @media #{$small-only} {
                  @media #{$portrait} {
                    width: 175px;
                  }
                }
              }
            }
          }
          .pr-multiselect {
            &.pr-multiselect-button-wasthisagift {
              display: none;
            }
            &.pr-multiselect-button-bestuses,
            &.pr-multiselect-button-ivebeenusingestéelauderfor {
              display: none;
            }
            &.pr-multiselect-button-describeyourself,
            &.pr-multiselect-button-age {
              display: none;
            }
            &.pr-multiselect-button-ireceivedafreetrialofthisproduct {
              display: none;
            }
            &.pr-multiselect-button-skinconcern,
            &.pr-multiselect-button-skintype {
              display: none;
            }
          }
        }
        .pr-rd-main-header-search {
          padding-left: 0;
          width: 100% !important;
          @media #{$medium-up} {
            width: 30%;
            #{$rdirection}: 0;
            position: absolute;
            height: fit-content;
          }
        }
      }
      .pr-review {
        .pr-rd-header {
          .pr-snippet-rating-decimal {
            display: none;
          }
          .pr-rd-review-headline {
            margin: 10px 0 0 0;
            clear: both;
            font-size: 12px;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            font-family: $akzidenz;
            font-weight: bold;
            color: $color-navy;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin !important;
              font-family: $futura_now_md;
            }
            #{$renutriv} & {
              color: $color-white !important;
            }
            padding-top: 2px;
          }
          .pr-snippet-stars-png {
            .pr-snippet-rating-decimal {
              display: none;
            }
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            font-size: 15px;
            color: $color-navy;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
            }
            #{$renutriv} & {
              color: $color-white;
            }
            @media #{$small-only} {
              padding-top: 10px;
            }
            em {
              font-weight: bold;
            }
          }
          .pr-rd-right {
            .pr-rd-reviewer-type {
              display: none;
            }
            .pr-rd-reviewer-details {
              p,
              time {
                font-size: 12px;
                color: $color-navy;
                #{$aerin} & {
                  color: $color-periwinkle-blue--aerin;
                }
                #{$renutriv} & {
                  color: $color-white;
                }
                margin-bottom: 7px;
              }
              p {
                .pr-rd-bold {
                  display: none;
                }
              }
            }
          }
        }
        .pr-rd-merchant-response {
          padding: 0 20px;
          #{$renutriv} & {
            background-color: $color-medium-black;
          }
          .pr-rd-description-text {
            font-size: 15px;
            font-family: $akzidenz;
            color: $color-navy;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
              font-family: $futura_now_md;
            }
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
          }
          .pr-rd-merchant-response-headline {
            strong {
              font-size: 15px;
              font-family: $akzidenz;
              color: $color-navy;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
              }
              #{$renutriv} & {
                color: $color-white;
              }
            }
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            outline: none;
            box-shadow: none;
            span {
              font-size: 12px;
              letter-spacing: 1.8px;
              text-transform: uppercase;
              font-weight: bold;
              color: $color-navy;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
              }
              #{$renutriv} & {
                color: $color-white;
              }
            }
            .pr-caret-icon__line {
              stroke: $color-navy;
            }
            .pr-caret-icon {
              background-image: url('/media/images/background-images/background-image13.png');
              background-size: 23px 43px;
              -webkit-appearance: none;
              background-repeat: no-repeat;
              background-position: 6px;
              transform: rotate(0deg);
              #{$aerin} & {
                background-image: url('/media/images/background-images/background-image15.png');
                background-size: 23px 43px;
              }
              #{$renutriv} & {
                background-image: url('/media/images/background-images/background-image14.png');
                background-size: 23px 43px;
              }
              -webkit-appearance: none;
              background-repeat: no-repeat;
              background-position: 6px;
              transform: rotate(0deg);
              svg {
                display: none;
              }
            }
          }
          .pr-accordion-content {
            dl {
              dt,
              dd {
                font-size: 12px;
                letter-spacing: 1px;
                color: $color-navy;
                #{$aerin} & {
                  color: $color-periwinkle-blue--aerin;
                }
                #{$renutriv} & {
                  color: $color-white;
                  background: $color-medium-black;
                }
              }
              dt {
                padding-bottom: 10px;
                &:after {
                  content: ':';
                }
              }
              dt[data-reactid-powerreviews$='wasthisagift.0'] {
                &:after {
                  display: none;
                }
              }
              dd {
                font-size: 12px;
              }
            }
            .pr-rd-tags,
            .pr-rd-subratings {
              width: 95%;
              clear: both;
              @media #{$small-only} {
                width: 100%;
              }
              dl {
                width: 100%;
              }
              dt {
                padding-left: 0px;
                width: 330px;
                letter-spacing: 1px;
                @media #{$small-only} {
                  @media #{$portrait} {
                    width: 47%;
                  }
                }
              }
              dd {
                display: table-row;
              }
            }
            .pr-rd-tags {
              dt {
                float: left;
              }
              dd {
                text-transform: capitalize;
              }
            }
          }
          .pr-rd-subratings {
            width: 95%;
            clear: both;
            @media #{$small-only} {
              margin-bottom: 0;
            }
            dl[data-reactid-powerreviews$='$msq-elistmember2'] {
              display: block;
              padding: 15px 0;
              @media #{$small-only} {
                padding: 10px 0 0;
                margin-bottom: 0;
              }
            }
            dt[data-reactid-powerreviews$='msq-elistmember2.0'] {
              display: none;
            }
            dd[data-reactid-powerreviews$='msq-elistmember2.1'] {
              font-size: 15px;
            }
          }
          .pr-rd-tags {
            margin-bottom: 5px;
            @media #{$small-only} {
              width: 100%;
            }
            .pr-rd-review-tag {
              margin-bottom: 0;
              width: auto;
              dd {
                padding-top: 5px;
                padding-bottom: 5px;
                &::after {
                  content: ',';
                }
                &:last-child {
                  &::after {
                    content: ' ';
                  }
                }
              }
              dt {
                padding-top: 0px;
                padding-bottom: 5px;
              }
              &:nth-child(2),
              &:nth-child(3) {
                display: none;
              }
            }
          }
          &.pr-accordion-exapanded {
            .pr-accordion-btn {
              .pr-caret-icon {
                transform: rotate(180deg);
              }
            }
          }
        }
        .pr-rd-footer {
          .pr-rd-bottomline {
            span {
              color: $color-navy;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
              }
              #{$renutriv} & {
                color: $color-white;
              }
              font-size: 15px;
            }
            .pr-rd-bold {
              display: none;
            }
            padding-bottom: 15px;
            margin-bottom: 0;
          }
          .pr-rd-helpful-action {
            display: inline-block;
            .pr-rd-helpful-text {
              display: none;
            }
          }
          .pr-helpful {
            &-count {
              font-size: 12px;
              text-transform: uppercase;
              color: $color-navy;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
              }
              #{$renutriv} & {
                color: $color-white;
              }
              font-weight: bold;
              &:hover {
                color: $color-navy;
                #{$aerin} & {
                  color: $color-periwinkle-blue--aerin;
                }
                #{$renutriv} & {
                  color: $color-white;
                }
              }
            }
            &-btn {
              border: 1px solid $color-medium-gray-blue;
              padding: 12px 18px;
              background: none;
              text-transform: capitalize;
              vertical-align: top;
              font-weight: bold;
              margin-right: 10px;
              @media #{$iphone5-portrait} {
                padding: 8px 4px;
                word-spacing: -3px;
              }
              @media #{$small-only} {
                @media #{$portrait} {
                  padding: 5px;
                }
              }
              .pr-thumbs-icon {
                display: none;
              }
              &.pr-helpful-active {
                background: none !important;
              }
            }
            &-yes {
              .pr-helpful-count {
                &:before {
                  content: $reviews_helpful;
                  visibility: visible;
                  text-transform: uppercase;
                  font-size: 12px;
                  margin-right: 1px;
                }
                &:after {
                  content: ')';
                  margin: 0 0 0 1px;
                }
                letter-spacing: 0.15em;
              }
            }
            &-no {
              .pr-helpful-count {
                &:before {
                  content: $reviews_not_helpful;
                  visibility: visible;
                  margin-right: 1px;
                  text-transform: uppercase;
                  font-size: 12px;
                }
                &:after {
                  content: ')';
                  margin: 0 0 0 1px;
                }
                letter-spacing: 0.15em;
              }
            }
          }
          .pr-rd-flag-review-container {
            position: relative;
            top: 10px;
            margin-left: 30px;
            display: inline;
            a {
              font-size: 12px;
              color: $color-navy;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
                font-family: $futura_now_md;
              }
              #{$renutriv} & {
                color: $color-white;
                font-family: $roboto-condensed;
              }
              text-transform: uppercase;
              font-weight: bold;
              text-decoration: underline;
              font-family: $akzidenz;
              @media #{$small-only} {
                @media #{$portrait} {
                  padding-left: 0;
                }
              }
            }
            @media #{$small-only} {
              @media #{$portrait} {
                margin-left: 0;
                top: 13px;
              }
              @media #{$landscape} {
                top: 20px;
              }
            }
            @media #{$medium-only} {
              top: 20px;
            }
          }
          .pr-modal-content {
            #{$renutriv} & {
              @media #{$small-only} {
                background: $color-medium-black;
              }
            }
            #pr-flag-reviews {
              input[type='radio'] {
                left: auto;
              }
              .pr-flagging-radio {
                span {
                  font-size: 12px;
                }
              }
              #{$renutriv} & {
                background: $color-medium-black;
                border: 1px solid $color-white;
                @media #{$small-only} {
                  border: none;
                }
              }
            }
            .pr-flag-review-btn {
              display: inline-block;
              width: auto;
              padding: 14px 19px 12px;
              overflow: visible;
              vertical-align: middle;
              cursor: pointer;
              color: $color-white;
              font-family: $akzidenz;
              font-size: 12px;
              text-transform: uppercase;
              font-weight: bold;
              text-decoration: none;
              text-align: center;
              letter-spacing: 0.15em;
              border-radius: 0;
            }
            .pr-flag-review-btn-cancel {
              background: $color-white;
              color: $color-black;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
                font-family: $futura_now_md;
              }
              #{$renutriv} & {
                font-family: $roboto-condensed;
              }
              border: 1px solid $color-light-gray;
            }
            .pr-flag-review-btn-submit,
            .pr-flag-review-btn-submit:hover {
              background: $color-navy;
              #{$aerin} & {
                background: $color-periwinkle-blue--aerin;
                font-family: $futura_now_md;
              }
              #{$renutriv} & {
                background-color: $color-medium-black;
                border: 1px solid $color-gold--renutriv;
                font-family: $roboto-condensed;
              }
              border: 1px solid $color-black;
            }
            .pr-control-label,
            .pr-flag-review-label {
              color: $color-black;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
                font-family: $futura_now_md;
              }
              #{$renutriv} & {
                color: $color-white;
                font-family: $roboto-condensed;
              }
              font-family: $akzidenz;
              font-size: 12px;
              text-transform: uppercase;
            }
          }
        }
        .pr-rd-images {
          margin-bottom: 0;
          .pr-rd-image-container {
            margin: 0 10px 15px 0;
          }
        }
      }
      .pr-rd-main-footer {
        border-top: 1px solid $color-light-gray;
        padding-top: 15px;
        .pr-rd-content-block {
          width: 100%;
          .pr-rd-to-top {
            padding-left: 25%;
            text-align: center;
            font-size: 15px;
            color: $color-navy;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
              font-family: $futura_now_md;
            }
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
            font-family: $akzidenz;
            @media #{$small-only} {
              padding-left: 0;
            }
            @media #{$medium-only} {
              padding-left: 0;
            }
          }
          .pr-rd-pagination-btn,
          .pr-rd-review-position span {
            font-size: 15px;
            color: $color-navy;
            font-family: $akzidenz;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
              font-family: $futura_now_md;
            }
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
          }
        }
      }
      &.pr-rd-display-tablet {
        .pr-rd-side-content-block {
          right: -36%;
          top: -55px;
        }
      }
      .pr-rd-no-reviews {
        font-family: $akzidenz;
        .pr-snippet-write-review-link {
          text-decoration: underline;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      .pr-rd-display-search-no-results {
        clear: both;
        .pr-rd-review-total {
          background: none;
        }
      }
      .pr-rd-main-header-with-filters {
        .pr-rd-main-header-search-sort {
          padding-top: 0;
          height: 40px;
          width: 100%;
          @media #{$medium-up} {
            position: relative;
            top: -35px;
            width: 44%;
            float: $rdirection;
          }
        }
        .pr-rd-review-header-contents ~ .pr-rd-main-header-search-sort {
          @media #{$medium-up} {
            top: -57px;
          }
        }
        .pr-rd-search-container {
          width: 100%;
          float: $rdirection;
          @media #{$medium-up} {
            width: 240px;
            margin-top: -40px;
          }
        }
        .pr-review-filter-info-bar {
          margin: 0;
        }
        .pr-multiselect-item-label {
          padding-#{$ldirection}: 3px;
          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.no-backgroundsize
  #pr-reviewdisplay
  .p-w-r
  .pr-review-display
  .pr-rd-main-header
  .pr-rd-review-header-contents
  .pr-multiselect-button
  .pr-caret-icon {
  background-image: url($base-theme-image-path + 'inline/select_arrow_mobile.png');
}
.no-backgroundsize
  .brand-aerin
  #pr-reviewdisplay
  .p-w-r
  .pr-review-display
  .pr-rd-main-header
  .pr-rd-review-header-contents
  .pr-multiselect-button
  .pr-caret-icon {
  background-image: url($base-theme-image-path + 'inline/select_arrow_mobile--periwinkle.png');
}
.no-backgroundsize
  .brand-renutriv
  #pr-reviewdisplay
  .p-w-r
  .pr-review-display
  .pr-rd-main-header
  .pr-rd-review-header-contents
  .pr-multiselect-button
  .pr-caret-icon {
  background-image: url($base-theme-image-path + 'inline/select_arrow_mobile--white.png');
}
.no-backgroundsize
  #pr-reviewdisplay
  .p-w-r
  .pr-review-display
  .pr-review
  .pr-accordion
  .pr-accordion-btn
  .pr-caret-icon {
  background-image: url($base-theme-image-path + 'inline/select_arrow_mobile.png');
}
.no-backgroundsize
  .brand-aerin
  #pr-reviewdisplay
  .p-w-r
  .pr-review-display
  .pr-review
  .pr-accordion
  .pr-accordion-btn
  .pr-caret-icon {
  background-image: url($base-theme-image-path + 'inline/select_arrow_mobile--periwinkle.png');
}
.no-backgroundsize
  .brand-renutriv
  #pr-reviewdisplay
  .p-w-r
  .pr-review-display
  .pr-review
  .pr-accordion
  .pr-accordion-btn
  .pr-caret-icon {
  background-image: url($base-theme-image-path + 'inline/select_arrow_mobile--white.png');
}
/* Global Styles */

/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
.product-brief,
.mpp__product,
.search-product__details {
  .p-w-r {
    @include pr-background-image();
    margin-left: 0;
    #{$aerin} & {
      @include pr-aerin-background-image();
    }
    #{$renutriv} & {
      @include pr-renutriv-background-image();
    }
    .product-grid-wrapper & {
      float: left;
      margin: 0 0 15px 0;
    }
    a {
      color: $color-navy;
      #{$aerin} & {
        color: $color-periwinkle-blue--aerin;
        font-family: $futura_now_md;
      }
      #{$renutriv} & {
        color: $color-white;
        font-family: $roboto-condensed;
      }
      &:hover {
        color: $color-navy;
        #{$aerin} & {
          color: $color-periwinkle-blue--aerin;
        }
        #{$renutriv} & {
          color: $color-white;
        }
        text-decoration: underline;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    span,
    p,
    strong {
      color: $color-navy;
      #{$aerin} & {
        color: $color-periwinkle-blue--aerin;
        font-family: $futura_now_md;
      }
      #{$renutriv} & {
        color: $color-white;
        font-family: $roboto-condensed;
      }
      font-family: $akzidenz;
      font-size: 15px;
      font-weight: normal;
    }
    .pr {
      &-textinput,
      &-textarea {
        @include input-placeholder {
          font-size: 15px;
          font-family: $akzidenz;
        }
      }
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal {
        display: none;
      }
      &-table-cell {
        padding: 0;
      }
      &-helper-text {
        color: $color-navy;
        #{$aerin} & {
          color: $color-periwinkle-blue--aerin;
        }
        #{$renutriv} & {
          color: $color-white;
        }
      }
      &-clear-all-radios {
        color: $color-blue--estee_edit;
        letter-spacing: 0;
        &:hover {
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
          }
          #{$renutriv} & {
            color: $color-white;
          }
          text-decoration: underline;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &-header-table {
        .pr-header-title {
          text-transform: none;
          font-weight: bold;
        }
      }
      &-snippet-stars-reco {
        &-inline {
          .pr-snippet-read-and-write {
            display: inline-block;
            a,
            span {
              color: $color-navy;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
              }
              #{$renutriv} & {
                color: $color-white;
              }
              font-size: 15px;
              font-family: $akzidenz;
            }
          }
          .pr-snippet-stars-container {
            text-align: center;
            vertical-align: middle;
          }
          &.pr-snippet-minimal {
            .pr-snippet-read-and-write {
              display: none;
              @media #{$medium-up} {
                display: inline-block;
              }
              .pr-snippet-review-count {
                margin: 0 10px 0 10px;
                padding: 0 0px 0 0px;
              }
              a {
                text-decoration: none;
              }
              a:hover {
                color: $color-navy;
                #{$aerin} & {
                  color: $color-periwinkle-blue--aerin;
                }
                #{$renutriv} & {
                  color: $color-white;
                }
                text-decoration: underline;
              }
            }
          }
        }
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
            font-family: $futura_now_reg;
          }
          #{$renutriv} & {
            color: $color-white;
            font-family: $roboto-condensed;
          }
          font-family: $akzidenz;
          font-size: 15px;
        }
        .pr-review {
          .pr-rd-header {
            .pr-rd-review-headline {
              color: $color-navy;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
              }
              #{$renutriv} & {
                color: $color-white;
                font-family: $roboto-condensed;
              }
              font-family: $akzidenz;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        }
        .pr-rd-description-text,
        .pr-rd-details {
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
            font-family: $futura_now_reg;
          }
          #{$renutriv} & {
            color: $color-white;
            font-family: $roboto-condensed;
          }
          font-size: 12px;
        }
        .pr-rd-def-list:nth-child(6) {
          display: none !important;
        }
        .pr-rd-author-nickname {
          span {
            font-size: 12px;
            font-family: $akzidenz;
            text-transform: uppercase;
            font-weight: bold;
          }
          .pr-rd-bold {
            display: none;
          }
        }
        .pr-rd-author-submission-date {
          time {
            color: $color-navy;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
            }
            #{$renutriv} & {
              color: $color-white;
            }
            text-transform: uppercase;
          }
        }
        .pr-rd-author-location {
          text-transform: uppercase;
          .pr-rd-bold {
            display: none;
          }
          span {
            font-size: 12px;
          }
        }
        .pr-rd-footer {
          padding: 0 0 10px 0;
        }
        .pr-rd-main-header {
          background: none;
          padding: 0 0 10px;
          h1,
          label {
            display: none;
          }
          .pr-rd-sort {
            font-family: $akzidenz;
            font-size: 13px;
            color: $color-navy;
            min-width: unset;
            width: max-content;
            cursor: pointer;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
            }
            #{$renutriv} & {
              color: $color-white;
              background-color: $color-medium-black;
            }
            border: none;
            background-color: transparent;
          }
          .pr-rd-search-container {
            @media #{$small-only} {
              width: 100%;
              float: left;
            }
          }
          .pr-rd-review-header-contents {
            background-color: transparent;
          }
        }
        .pr-rd-description {
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
          }
          #{$renutriv} & {
            color: $color-white;
          }
          font-family: $akzidenz;
          font-size: 12px;
        }
      }
    }
    .pr-btn-review {
      background-color: $color-navy;
      #{$aerin} & {
        background-color: $color-periwinkle-blue--aerin;
      }
    }
    .pr-btn-review:hover {
      color: $color-white;
    }
    .pr-rd-search-reviews-input {
      input {
        font-family: $akzidenz;
        font-size: 15px;
        padding-left: 10px;
        border: 1px solid $color-light-gray;
        &::placeholder {
          font-style: italic;
          color: $color-periwinkle-blue--aerin;
          letter-spacing: 0.5px;
        }
      }
    }
    .pr-star-v4,
    .pr-btn {
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

#power_review_container {
  .product-full {
    width: 1024px;
    margin: 0 auto;
    margin-top: 30px;
    .product-full__image {
      img {
        width: 250px;
        height: auto;
        float: left;
        margin-right: 30px;
        @media #{$small-only} {
          float: none;
          margin-right: 0;
        }
      }
      @media #{$small-only} {
        text-align: center;
      }
    }
    .product-full__details {
      float: left;
      width: 70%;
      .product-full__name {
        font-family: $optimaregular;
        font-size: 30px;
        line-height: 31px;
      }
      .js-pr-subheading {
        font-family: $optimaregular;
      }
      @media #{$small-only} {
        margin-top: 15px;
        float: none;
        width: 100%;
      }
      @media #{$medium-only} {
        width: 45%;
      }
    }
    @media #{$small-only} {
      width: auto;
      padding: 20px;
    }
  }
}

.write_a_review__container {
  #pr-write {
    padding-top: 10px;
    .p-w-r {
      @include pr-large-background-image();
      #{$aerin} & {
        @include pr-aerin-large-background-image();
      }
      #{$renutriv} & {
        @include pr-renutriv-large-background-image();
      }
      .pr-header {
        clear: both;
        border-bottom: none;
        margin: 0;
        .pr-header-title {
          display: none;
        }
        .pr-header-table {
          margin: 0;
        }
      }
      span {
        font-size: 12px;
        font-weight: bold;
      }
      #pr-war-form {
        clear: both;
        border-top: 1px solid $color-light-gray;
        padding-top: 20px;
        #pr-bottomline {
          .pr-btn-default {
            &.active {
              background-color: $color-navy;
              #{$aerin} & {
                background-color: $color-periwinkle-blue--aerin;
              }
            }
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
          }
          .btn-group-radio-vertical {
            .pr-btn {
              text-transform: none;
            }
          }
        }
        .pr-media_image-form-group,
        .pr-media_videourl-form-group {
          .pr-btn-default {
            background-color: $color-navy;
            #{$aerin} & {
              background-color: $color-periwinkle-blue--aerin;
            }
            border: none;
            span {
              color: $color-white;
              text-transform: uppercase;
              font-weight: bold;
              letter-spacing: 1px;
              &:after {
                content: ' ';
              }
            }
            &.active {
              background-color: $color-navy;
              #{$aerin} & {
                background-color: $color-periwinkle-blue--aerin;
              }
            }
          }
          width: 85%;
          @media #{$small-only} {
            width: 100%;
          }
          .pr-btn-danger {
            background-color: $color-navy;
            #{$aerin} & {
              background-color: $color-periwinkle-blue--aerin;
            }
            border: none;
          }
          .pr-media-preview {
            .form-group {
              display: none;
            }
            .pr-loading-indicator {
              margin: 3% auto;
            }
          }
        }
        .pr-bestuses-form-group,
        .pr-cons-form-group,
        .pr-wasthisagift-form-group,
        .pr-email_collection-form-group,
        .pr-elistmember-form-group,
        .pr-describeyourself-form-group,
        .pr-loyaltymember2-form-group,
        .pr-elistmember2-form-group {
          display: none;
        }
        .pr-rating-form-group {
          span {
            font-weight: bold;
          }
        }
        .pr-name-form-group {
          span {
            font-weight: bold;
          }
        }
        .form-group {
          select {
            -webkit-appearance: menulist;
            max-width: initial;
            font-size: 15px;
            text-transform: none;
          }
        }
        .pr-control-label {
          span {
            &:nth-child(2) {
              float: left;
              position: relative;
              top: 3px;
            }
          }
        }
        .pr-rating-stars {
          margin-top: 10px;
          position: relative;
          right: 4px;
        }
        .pr-wasthisagift-form-group {
          .pr-btn-default {
            &.active {
              background-color: $color-navy;
              #{$aerin} & {
                background-color: $color-periwinkle-blue--aerin;
              }
            }
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
          }
        }
        .input-group {
          @media #{$small-only} {
            display: table;
          }
          .pr-btn {
            background-color: $color-navy;
            #{$aerin} & {
              background-color: $color-periwinkle-blue--aerin;
            }
            color: $color-white;
          }
        }
      }
      .pr-clear-all-radios {
        color: $color-navy;
        #{$aerin} & {
          color: $color-periwinkle-blue--aerin;
        }
      }
      .pr-btn-add-tag {
        a {
          &:after {
            display: none;
          }
          border: solid 1px $color-navy;
          background: $color-navy;
          #{$aerin} & {
            background: $color-periwinkle-blue--aerin;
          }
          color: $color-white;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 12px;
        }
      }
      .pr-btn-fileinput {
        span {
          &:after {
            color: $color-navy;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
            }
          }
        }
      }
      .pr-submit {
        a {
          font-size: 12px;
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
      .form-control {
        color: $color-navy;
        #{$aerin} & {
          color: $color-periwinkle-blue--aerin;
        }
        box-shadow: none;
        font-size: 15px;
        &::placeholder {
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
          }
          opacity: 0.4;
          text-transform: none;
          font-size: 15px !important;
          @media #{$small-only} {
            font-size: 14px !important;
            letter-spacing: 0.2px;
          }
        }
      }
      .pr-footer {
        margin-bottom: 60px;
        border: none;
        padding-top: 0;
        .pr-subscript {
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
          }
          text-transform: uppercase;
          line-height: 1.4;
          font-size: 12px;
        }
        .pr-btn-default {
          text-transform: uppercase;
          font-size: 12px;
          border: none;
        }
      }
      .tag-group {
        .pr-label-control {
          &.checked {
            background-color: $color-navy;
            #{$aerin} & {
              background-color: $color-periwinkle-blue--aerin;
            }
            .pr-tag-label {
              color: $color-white;
              text-transform: uppercase;
            }
          }
        }
      }
      .thank-you-page {
        .header {
          .title,
          .subtitle {
            color: $color-navy;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
            }
            font-family: $optimaregular;
            font-weight: normal;
          }
        }
        #loyaltymember2,
        #elistmember2 {
          display: none;
        }
        .pr-dark {
          font-size: 12px;
          color: $color-navy;
          #{$aerin} & {
            color: $color-periwinkle-blue--aerin;
          }
          display: inline-block;
          width: 60%;
          font-weight: bold;
          @media #{$small-only} {
            width: 55%;
          }
          &::after {
            content: ':';
          }
        }
        .pr-text {
          &[data-reactid-powerreviews='.0.0.1.2.3.4'] {
            .pr-dark {
              &::after {
                content: ' ';
              }
            }
          }
        }
        .pr-rating-number,
        .pr-rating-date {
          display: none;
        }
        .review-preview {
          .headline {
            color: $color-navy;
            #{$aerin} & {
              color: $color-periwinkle-blue--aerin;
            }
          }
          #war-ty-cons,
          #war-ty-best-uses {
            display: none;
          }
          #war-ty-pros {
            .pr-attribute-header {
              background: transparent;
              padding-left: 0;
              color: $color-navy;
              #{$aerin} & {
                color: $color-periwinkle-blue--aerin;
              }
            }
            .pr-attribute-list {
              .pr-attribute {
                padding-left: 0;
                font-size: 15px;
                color: $color-navy;
                #{$aerin} & {
                  color: $color-periwinkle-blue--aerin;
                }
                font-family: $akzidenz;
                text-transform: capitalize;
              }
            }
          }
        }
      }
      .pr-has-error {
        .form-control {
          border-color: $color-red;
          &:focus {
            border-color: $color-red;
          }
        }
        .pr-control-label {
          span {
            color: $color-red;
          }
        }
      }
      .btn-group {
        .pr-btn {
          box-shadow: none;
        }
      }
      input[type='text']::placeholder,
      textarea::placeholder {
        font-size: 12px;
      }
      .form-group {
        width: 85%;
        @media #{$small-only} {
          width: 100%;
        }
        &#pr-name,
        &#pr-headline,
        &#pr-location {
          width: 100%;
        }
        #pr-bottomline {
          .pr-helper-text {
            display: none;
          }
        }
      }
      .pr-alert-container {
        &#pr-warning {
          display: none;
        }
        &#pr-alert {
          display: block;
        }
      }
    }
    .pr-table-cell {
      display: block;
      &.pr-header-product-img {
        display: none;
      }
      .pr-header-required {
        display: inline;
        float: right;
        text-transform: uppercase;
        @media #{$iphone5-portrait} {
          float: none;
          display: block;
          margin: 0;
          position: initial;
        }
      }
    }
    .alert-danger,
    .pr-alert-danger {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-black;
      font-family: $optimaregular;
      p {
        font-family: $optimaregular;
      }
    }
  }
}

.discover-more,
.spp-howtouse__product,
.spp_howtouse__product {
  .p-w-r {
    @include pr-background-image();
    #{$aerin} & {
      @include pr-aerin-background-image();
    }
    #{$renutriv} & {
      @include pr-renutriv-background-image();
    }
    .pr-snippet {
      .pr-snippet-stars-png {
        #{$renutriv} & {
          background-color: transparent;
        }
        .pr-star-v4 {
          width: 12px;
          height: 10px;
        }
        .pr-snippet-rating-decimal {
          display: none;
        }
      }
      .pr-category-snippet__total {
        font-size: 12px;
        font-family: $akzidenz;
        padding-top: 4px;
        color: $color-navy;
        #{$aerin} & {
          color: $color-periwinkle-blue--aerin;
        }
        #{$renutriv} & {
          color: $color-white;
        }
        @media #{$iphone5-portrait} {
          padding: 0;
        }
        @media #{$iphone6-portrait} {
          padding: 0;
        }
        &:before {
          content: '(';
          visibility: visible;
        }
        &:after {
          content: ')';
          visibility: visible;
        }
      }
      .pr-category-snippet__item {
        @media #{$iphone5-portrait} {
          display: block;
        }
        @media #{$iphone6-portrait} {
          display: block;
        }
      }
    }
    .pr-no-reviews {
      .pr-category-snippet__total {
        display: none;
      }
    }
  }
}

.spp-howtouse__product {
  .p-w-r {
    .pr-category-snippet {
      display: block;
      text-align: center;
    }
    .pr-category-snippet__item {
      @media #{$small-only} {
        display: inherit;
      }
    }
  }
}

.discover-more {
  .p-w-r {
    .pr-category-snippet {
      @media #{$small-only} {
        display: block;
        text-align: center;
      }
    }
    .pr-category-snippet__item {
      @media #{$small-only} {
        display: inherit;
      }
    }
  }
}

.chat-reviews-page {
  #pr-reviewdisplay {
    #pr-image-display {
      display: none;
    }
  }
}

#power_review_container {
  #pr-war-form {
    .pr-loyaltymember-form-group,
    .pr-imanestéeelistloyaltymemberandreceivedpointsforthi-form-group {
      display: none;
    }
  }
}

.search-product__details {
  .p-w-r {
    .pr-snippet {
      .pr-star-v4 {
        .pr-snippet-stars-png {
          width: 12px;
          height: 10px;
        }
      }
    }
  }
}

.esearch-product__rating {
  .p-w-r {
    .pr-no-reviews {
      .pr-snippet {
        display: none;
      }
    }
  }
}

#pr-war-form {
  .pr-loyaltymember2-form-group,
  .pr-elistmember2-form-group {
    display: none;
  }
}
