.r3__container {
  width: 94%;
  @include swap_direction(margin, 3%);
  background: url(/media/erbjudanden/offers/r3/lp001_mobile_anr_jar_675x504.jpg) no-repeat 50% 75px;
  background-size: 210px;
  @include min-height(260px);
  @media #{$medium-up} {
    width: 94%;
    @include swap_direction(margin, 14px 3% 100px 3%);
    @include min-height(504px);
    background: url(/media/erbjudanden/offers/r3/lp001_anr_jar_pt_675x504.jpg) no-repeat 100% 0;
    background-size: auto;
  }
  .bold {
    font-weight: bold;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .r3__states {
    width: 100%;
    @include swap_direction(padding, 20px 0 0 0);
    @media #{$medium-up} {
      width: 58%;
      height: 504px;
      @include swap_direction(padding, 52px 0 0 0);
    }
    .r3__sub-header,
    .r3__header {
      width: 100%;
      text-align: center;
    }
    .r3__sub-header {
      font-size: 12px;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 8px;
      @media #{$medium-up} {
        margin-bottom: 14px;
      }
    }
    p {
      @include swap_direction(margin, 1em 0);
      font-size: 12px;
      line-height: normal;
      text-transform: uppercase;
      @media #{$medium-up} {
        text-transform: none;
        line-height: 20px;
        letter-spacing: 0.15em;
      }
    }
    .r3__notice {
      font-size: 9px;
      line-height: normal;
      color: $color-medium-gray;
    }
  }
  .js-offer-active,
  .js-offer-inactive,
  .js-offer-ended {
    width: 90%;
    @include swap_direction(margin, 170px 5% 0 5%);
    text-align: center;
    @media #{$medium-up} {
      width: 210px;
      @include swap_direction(margin, 22px 0 0 17%);
      text-align: $ldirection;
    }
    .r3__add-to-bag,
    .r3__todays-offers {
      width: 100%;
      height: 45px;
      line-height: 45px;
      margin-top: 10px;
      @media #{$medium-up} {
        width: auto;
        height: 33px;
        line-height: 33px;
      }
    }
  }
  .js-offer-active {
  }
  .js-offer-inactive {
    width: 227px;
  }
  .js-offer-ended {
  }
}

.r3__overlay {
  width: 750px;
  text-align: center;
  color: $color-navy;
  .r3__overlay-header {
    font-size: 40px;
    font-family: $optimalight;
  }
  p {
    @include swap_direction(margin, 1em 0);
    font-size: 12px;
    text-transform: uppercase;
    &.r3__overlay-offer {
      color: $color-medium-gray;
      font-size: 15px;
      text-transform: none;
    }
  }
  .bold {
    font-weight: bold;
  }
}
